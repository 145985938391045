import React from 'react'
// Components
import { PageHeader, Seo } from '../components'
// Context
import { usePrismic } from '../context/PrismicContext'
// Styles
import {
  Wrapper,
  Container,
  Title,
  StyledSpan,
  StyledImage,
} from '../styles/CorporatePage.style'

const AboutNew = () => {
  const {
    prismicData: {
      prismicAboutPage: {
        best_stars_content,
        best_stars_image,
        best_stars_title,
        making_difference_content,
        making_difference_image,
        making_difference_title,
        page_title,
        spreading_quintessential_content,
        spreading_quintessential_image,
        spreading_quintessential_title,
        title,
      },
    },
  } = usePrismic()

  return (
    <>
      <Seo title={page_title[0].text} />
      <Wrapper>
        <PageHeader exitRoute="/">{title[0].text}</PageHeader>

        <Container>
          <StyledImage src={making_difference_image.url} />
        </Container>
        <Container padded noPaddingBottom>
          <Title bigger>{making_difference_title[0].text}</Title>
        </Container>
        <Container padded>
          {making_difference_content.map((item, index) => (
            <StyledSpan key={`makingDifference-${index}`}>
              {item.text}
            </StyledSpan>
          ))}
        </Container>

        <Container>
          <StyledImage src={spreading_quintessential_image.url} />
        </Container>
        <Container padded noPaddingBottom>
          <Title bigger>{spreading_quintessential_title[0].text}</Title>
        </Container>
        <Container padded noPaddingBottom>
          {spreading_quintessential_content.map((item, index) => (
            <StyledSpan key={`spreadingQuintessential-${index}`}>
              {item.text}
            </StyledSpan>
          ))}
        </Container>
      </Wrapper>
    </>
  )
}

export default AboutNew
